<template>
  <a :is="element.config.action === 'dashboard' || preview || !element.config.url ? 'span' : 'a'"
     :href="element.config.url" target="_blank"
     style="text-decoration: none"
  >
    <div
        @click="handleAction"
        :style="buttonStyle" class="wsRoundedLight d-flex pointer"
         :class="[{'justify-center' : element.config.full_width && element.config.style !== 'text'}]"
    >
      <span :style="textFieldStyle">{{  element.content  }}</span>
    </div>
  </a>

</template>
<script>
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailButtonElementViewer",
  mixins : [finemailer],
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  computed : {
    textFieldStyle() {
      let style = ''

      if ( this.element.config.style === 'primary') {
        style += `color : ${this.EMAIL_CONFIG_COLOR('button_text')};`
        style += `text-align: center;`
      } else if (this.element.config.style === 'text') {
        style += `color : ${this.EMAIL_CONFIG_COLOR('link')};`
      }  else {
        style += `color : ${this.EMAIL_CONFIG_COLOR('secondary_button_text')};`
        style += `text-align: center;`
      }

      style += `font-size: 14px;`
      style += `font-weight: 500 !important ;`
      style += `padding: 0 !important ;`

      return style
    },
    buttonStyle() {
      let style = ''

      if ( this.element.config.style === 'primary') {
        style += `background-color: ${ this.EMAIL_CONFIG_COLOR('button_bg')}; `
      } else  if ( this.element.config.style === 'secondary') {
        style += `border: 1px solid ${this.EMAIL_CONFIG_COLOR('secondary_button_bg')}; `
      }

      if (this.element.config.style !== 'text') {
        style += `padding : 8px 24px;`
      }

      if ( this.element.config.full_width && this.element.config.style !== 'text') {
        style += `width : 100%;`
      }

      return style
    },
    config() {
      return this.element.config || {}
    }
  },
  methods : {
    handleAction() {
      if ( this.preview ) {
        return
      }
      if ( !this.config.action ) {
        this.notify(this.$t('mailer.editor.button.no_action_text') ,'warning')
      }
      else if ( this.config.action === 'dashboard') {
        this.notify(this.$t('mailer.editor.button.preview_dashboard_text')  )
      }
      else if ( this.config.action === 'link' && !this.config.url ) {
        this.notify(this.$t('mailer.editor.button.no_url_text') , 'warning')
      }


    }
  }
}
</script>

<style scoped>

</style>