<template>

  <div class="py-3" :style="`width: ${element.config.width || 100}%`">
    <div :style="lineStyle"/>
  </div>

</template>

<script>
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailTextElementEditor",
  mixins : [finemailer],
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    }
  },
  computed : {
    lineStyle() {
      let style = ''
      style += `background-color : ${this.element.config.color ? this.EMAIL_COLOR(this.element.config.color) : this.EMAIL_CONFIG_COLOR('line') };`
      style += `height : ${this.EMAIL_COLOR(this.element.config.size || 1)}px;`
      return style
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
</style>