<template>

<v-sheet :style="bodyMarginStyle"  :color="designReady ? EMAIL_CONFIG_COLOR('background') : wsLIGHTCARD" >
  <v-sheet
      :width="!EMAIL_MOBILE_VIEW ? 700 : 320"
      class="mx-auto"
      v-if="designReady"
      :style="emailBodyStyle"
  >
    <email-header-editor
        preview
    />

    <email-block-view
        v-for="(block,index) in newsletter.blocks" :key="block"
        :uuid="block"
        :index="index"
        :is-last="index === newsletter.blocks.length - 1"
        :preview="preview"
    />

    <email-footer-editor
        preview
    />


  </v-sheet>

</v-sheet>
</template>

<script>
import finemailer from "@/mixins/finemailer";
import {mapActions} from "vuex";
import newsletterConfig from '@/assets/json/finemailer/newsletterConfig.json'
import emailHeaderEditor
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/emailHeaderEditor.vue";
import emailFooterEditor
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/editor/emailFooterEditor.vue";
import emailBlockView from "@/components/pages/businessDashboard/fineMailer/emailConstructor/viewer/emailBlockView.vue";

export default {
  name: "emailView",
  components: {
    emailFooterEditor,
    emailHeaderEditor,
    emailBlockView
  },
  mixins : [finemailer],
  props : {
    entity : {
      type : Object,
      default () { return {} }
    },
    uuid : {
      type : String
    },
    preview : {
      type : Boolean,
      default: false
    }
  },
  data() {
    return {
      newsletter : {},
      designReady : false
    }
  },
  computed : {
    isReady() {
      return this.entity && !!this.entity.uuid
    },
    bodyMarginStyle() {
      if (!this.designReady) {
        return ''
      }
      let style = ''

      if ( this.EMAIL_CONFIG.body_margin_top) {
        style += `padding-top: ${this.EMAIL_CONFIG.body_margin_top}px;`
      }

      if ( this.EMAIL_CONFIG.body_margin_bottom) {
        style += `padding-bottom: ${this.EMAIL_CONFIG.body_margin_bottom}px;`
      }

      return style
    },
    emailBodyStyle() {
      if (!this.designReady) {
        return ''
      }
      let style = ''
      style += `background-color: ${this.EMAIL_CONFIG_COLOR('email')};`

      if ( this.EMAIL_CONFIG.body_padding_top) {
        style += `padding-top: ${this.EMAIL_CONFIG.body_padding_top}px;`
      }
      if ( this.EMAIL_CONFIG.body_padding_bottom) {
        style += `padding-bottom: ${this.EMAIL_CONFIG.body_padding_bottom}px;`
      }
      if ( this.EMAIL_CONFIG.body_padding_left) {
        style += `padding-left: ${this.EMAIL_CONFIG.body_padding_left}px;`
      }
      if ( this.EMAIL_CONFIG.body_padding_right) {
        style += `padding-right: ${this.EMAIL_CONFIG.body_padding_right}px;`
      }

      if ( this.EMAIL_CONFIG.body_margin_top) {
        style += `margin-top: ${this.EMAIL_CONFIG.body_margin_top}px;`
      }

      if ( this.EMAIL_CONFIG.body_margin_bottom) {
        style += `margin-bottom: ${this.EMAIL_CONFIG.body_margin_bottom}px;`
      }

      return style
    },
    entityUuid() {
      return this.entity.uuid
    }
  },
  watch : {
    entityUuid(value) {
      if ( value ) {
        this.prepareExistingDesign()
      }

    },
    uuid(value) {
      if ( value) {
        this.initPage()
      }
    }
  },
  methods : {

    ...mapActions('finemailer' , [
      'GET_NEWSLETTER_DESIGN'
    ]),
    async getDesign() {
      if ( !this.uuid) {
        return
      }
      let result = await this.GET_NEWSLETTER_DESIGN(this.uuid)

      if (!result) {
        this.ERROR()
      }
      this.newsletter = this.COPY(result)
      this.$store.state.businessConfig = result.business_config
      this.$store.state.finemailer.selectedNewsletter = result
      this.$store.state.finemailer.newsletterConfig = result.config
      this.PREPARE_EMAIL_CONFIG()
      this.designReady = true
    },
    prepareExistingDesign() {
      if ( this.entity && this.entity.uuid ) {
        this.newsletter = this.COPY(this.entity)
        this.designReady = true
      }
    },

    async initPage() {
      this.prepareExistingDesign()
      await this.getDesign()
      this.$emit('body-color' , this.EMAIL_CONFIG_COLOR('background'))
    },

  },
  beforeMount() {
    this.$store.state.finemailer.defaultNewsletterConfig = newsletterConfig
  },
  async mounted() {
    this.initPage()
  },

}
</script>

<style scoped>

</style>