<template>
  <div :style="textFieldStyle" v-html="text"></div>
</template>

<script>
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailTextElementViewer",
  mixins : [finemailer],
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    }
  },
  computed : {
    text() {
      return this.element.content.replace(/\n/g, '<br>')
    },
    textFieldStyle() {
      let style = ''
      style += `color : ${this.EMAIL_CONFIG_COLOR(this.element.config.font_style)};`
      style += `font-size : ${(parseInt(this.GET_EMAIL_FONT_SIZE(this.element.config.font_style)) || 14) + 'px'};`
      style += `text-align : ${this.element.config.align || 'left'};`
      style += `font-weight : ${ ['p','h6'].includes(this.element.config.font_style)  ? this.EMAIL_CONFIG.font_weight_paragraph : this.EMAIL_CONFIG.font_weight_heading};`
      style += `line-height : ${ ['p','h6'].includes(this.element.config.font_style)  ? this.EMAIL_CONFIG.font_line_height_paragraph : this.EMAIL_CONFIG.font_line_height_heading}%;`


      style += `text-transform : ${ ['p','h6'].includes(this.element.config.font_style)  ? this.EMAIL_CONFIG.font_case_paragraph : this.EMAIL_CONFIG.font_case_heading};`

      style += `font-family : ${ this.EMAIL_CONFIG[ ['p','h6'].includes(this.element.config.font_style)
          ? 'font_family_paragraph' : 'font_family_heading'] || 'Courier'};`

      return style
    },
    config() {
      return this.element.config
    }
  }
}
</script>

<style scoped>

</style>