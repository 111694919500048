<template>
  <div>
    <v-sheet :color="wsLIGHTCARD" class="pa-6">


      <div v-if="EMAIL_CONFIG.socials && EMAIL_CONFIG.socials.length > 0"
           class="d-flex align-center mb-4"
           :class="[
               {'justify-center' :  EMAIL_CONFIG.footer_align=== 'center'},
               {'justify-end' :  EMAIL_CONFIG.footer_align=== 'right'}
           ]"
      >
        <div v-for="(social,i) in EMAIL_CONFIG.socials" :key="i">
          <v-sheet
              v-if="IS_WESTUDY_ICON(social.img)"
              color="transparent"
              :src="social.img"
              :style="`
                 -webkit-mask: url('${social.img}') no-repeat ;
                 mask: url('${social.img}') no-repeat 50% 50%;
                 mask-size : contain;
                 position:relative;
                 background-color: ${EMAIL_CONFIG_COLOR('socials_color')} !important;`
              "
              :height="social.size || 24"
              :width="social.size || 24"
              contain
              :class="[{'mr-3' : i < EMAIL_CONFIG.socials.length - 1}]"
              :key="social.img"
          />
          <ws-img
              v-else
              :src="social.img"
              :height="social.size || 34"
              :width="social.size || 34"
              class="mr-3"
              contain
          />
        </div>
      </div>

      <h5 class="font-weight-bold  mb-4"
          :class="`text-${EMAIL_CONFIG.footer_align || 'center'}`"
      >
        {{ $store.state.business.selectedBusiness.name }}
      </h5>

      <h5 class="font-weight-regular"
          :class="`text-${EMAIL_CONFIG.footer_align || 'center'}`"
      >
        {{  $t('Telephone') }}: +380000000000
      </h5>
      <h5 :class="`text-${EMAIL_CONFIG.footer_align || 'center'}`"
          class="font-weight-regular"
      >
        {{  $t('Email') }}: info@westudy.ua
      </h5>
      <h5 :class="`text-${EMAIL_CONFIG.footer_align || 'center'}`"
          class="font-weight-regular"
      >
        {{  $t('Address') }}: Вулиця стрийська 105
      </h5>

      <h5 :class="`text-${EMAIL_CONFIG.footer_align || 'center'}`"
          class=" font-weight-regular mt-4"
      >
        {{ $t('EmailSubscriptionText') }}
        <span class="font-weight-bold">{{ $store.state.business.selectedBusiness.name }}</span>
      </h5>

      <h5 :class="`text-${EMAIL_CONFIG.footer_align || 'center'}`"
          class="font-weight-regular mt-4"
      >
        {{ $t('mailer.editor.footer.unsubscribe_text') }}
        <span :style="`color : ${wsATTENTION}`" style="text-decoration: underline">
          {{ $t('mailer.editor.footer.unsubscribe_button') }}
        </span>
      </h5>

    </v-sheet>

  </div>
</template>

<script>
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailFooterEditor",
  mixins : [finemailer],
}
</script>

<style scoped>

</style>