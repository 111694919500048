<template>
<div class="d-flex"
     :class="[
               {'justify-center' :  EMAIL_CONFIG.logo_align=== 'center'},
               {'justify-end' :  EMAIL_CONFIG.logo_align=== 'right'}
     ]"
>
  <ws-logo v-if="!EMAIL_CONFIG.logo" :title="$store.state.business.selectedBusiness.name" />

  <img v-else :src="EMAIL_CONFIG.logo" :height="`${EMAIL_CONFIG.logo_height || 50}px`" width="auto">

</div>
</template>

<script>
import finemailer from "@/mixins/finemailer";

export default {
  name: "emailHeaderEditor",
  mixins : [finemailer],
}
</script>

<style scoped>

</style>