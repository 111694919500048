<template>
<v-sheet
    class="d-flex "
    :style="blockStyle"
    :class="[{'flex-row' : !EMAIL_MOBILE_VIEW}, {'flex-column' : EMAIL_MOBILE_VIEW}]"
>
  <email-column-viewer
      v-for="column in block.config.columns" :key="column"
      :elements="getColumnElements(column)"
      :column="column"
      :block="block"
      :preivew="preview"
  />
</v-sheet>
</template>
<script>
import finemailer from "@/mixins/finemailer";
import {mapActions} from "vuex";
import emailColumnViewer
  from "@/components/pages/businessDashboard/fineMailer/emailConstructor/viewer/emailColumnViewer.vue";

export default {
  name: "emailBlockView",
  mixins : [finemailer],
  components : {
    emailColumnViewer
  },
  props : {
    uuid : {
      type : String,
    },
    index : {
      type : Number,
    },
    isLast : {
      type : Boolean,
      default : false
    },
    preview : {
      type : Boolean,
      default: false
    }
  },
  data() {
    return {
      block : {
        config : {},
        elements : []
      },
      display : false
    }
  },
  computed : {
    blockStyle() {
      let style = ''

      if ( this.block.config.padding_top) {
        style += `padding-top : ${this.block.config.padding_top}px;`
      }
      if ( this.block.config.padding_bottom) {
        style += `padding-bottom : ${this.block.config.padding_bottom}px;`
      }
      if ( this.block.config.padding_left) {
        style += `padding-left : ${this.block.config.padding_left}px;`
      }
      if ( this.block.config.padding_right) {
        style += `padding-right : ${this.block.config.padding_right}px;`
      }

      if ( this.block.config.background_color ) {
        style += `background-color : ${this.EMAIL_COLOR(this.block.config.background_color)} !important; `
      } else {
        style += `background-color : transparent !important;`
      }

      if ( this.block.config.border ) {
        style += `border : ${this.block.config.border}px solid  ${this.EMAIL_COLOR(this.block.config.border_color)} !important; `
      }

      if ( this.block.config.border_radius ) {
        style += `border-radius : ${this.block.config.border_radius}px !important; `
      }

      if ( this.block.config.margin_top ) {
        style += `margin-top : ${this.block.config.margin_top}px !important; `
      }

      if ( this.block.config.margin_bottom ) {
        style += `margin-bottom : ${this.block.config.margin_bottom}px !important; `
      }

      return style
    },
  },
  methods : {
    ...mapActions('finemailer' , [
      'GET_NEWSLETTER_DESIGN_BLOCK',
    ]),

    getColumnElements(column) {
      return this.block.elements.filter(el => el.column === column).sort((a, b) => a.order_number - b.order_number);
    },

    async initPage() {
      let result = await this.GET_NEWSLETTER_DESIGN_BLOCK(this.uuid)
      if (!result) {
        return
      }
      this.block = result
      this.display = true
      if ( this.$store.state.finemailer.newBlockId === this.block.uuid ) {
        this.selectBlock()
      }
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>



<style scoped>

</style>